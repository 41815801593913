import { useState } from "react"

import { CreatePresetDialog } from "src/components/MonitoringPresets/CreateNoiseMonitoringPresetDialog"
import { NoiseMonitoringPresetTable } from "src/components/MonitoringPresets/NoiseMonitoringPresetTable"
import { useMonitoringPresetBreadcrumbs } from "src/components/MonitoringPresets/useMonitoringPresetBreadcrumbs"
import { useUrlPager } from "src/components/Pager/useUrlPager"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchNoiseMonitoringPresets } from "src/data/profileSettings/queries/monitoringPresetQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { BreadcrumbView } from "src/ui/Layout/BreadcrumbView"

type NoiseMonitoringPresetsProps = { hidden?: boolean }

export function NoiseMonitoringPresets({
  hidden,
}: NoiseMonitoringPresetsProps) {
  const { t, langKeys } = useTranslate()
  const { limit, offset, setOffset } = useUrlPager()

  const { orgId } = useOrganization()

  const breadcrumbs = useMonitoringPresetBreadcrumbs()

  const fetchNoiseMonitoringPresets = useFetchNoiseMonitoringPresets({
    orgId,
    filters: { limit, offset },
  })

  const presets = fetchNoiseMonitoringPresets.data?.profiles

  const [createPresetDialogOpen, setCreatePresetDialogOpen] = useState(false)

  if (hidden) {
    return null
  }

  return (
    <BreadcrumbView
      breadcrumbs={breadcrumbs.noise}
      title={t(langKeys.noise_monitoring_presets_title)}
      description={t(langKeys.presets_noise_description)}
      actionBar={
        <MButton
          onClick={() => setCreatePresetDialogOpen(true)}
          variant="secondary"
        >
          {t(langKeys.presets_create_new_preset)}
        </MButton>
      }
    >
      <NoiseMonitoringPresetTable
        presets={presets}
        presetsTotalCount={fetchNoiseMonitoringPresets.data?.paging.total_count}
        setOffset={setOffset}
        limit={limit}
        offset={offset}
      />

      {presets && (
        <CreatePresetDialog
          open={createPresetDialogOpen}
          onClose={() => setCreatePresetDialogOpen(false)}
        />
      )}
    </BreadcrumbView>
  )
}
